import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider } from 'views/estimate-project/components/divider';
import { FormSection } from 'views/estimate-project/components/form-section';
import { OptionGrid } from 'views/estimate-project/components/option-grid';
import { StepWrapper } from 'views/estimate-project/components/step-wrapper';
import { Input } from 'components/input-new';

import {
    SCOPE_OPTIONS,
    SERVICES_FORM_FIELDS,
    SERVICES_OPTIONS,
    SERVICES_STEP_FIELDS,
} from './_constants';

const SInputContainer = styled.div`
    margin-top: 1rem;
    margin-left: 1.625rem;
`;

export const ServicesStep = () => {
    const { formatMessage } = useIntl();
    const {
        formState: { errors, isSubmitted },
        control,
        watch,
        trigger,
    } = useFormContext();

    const {
        [SERVICES_STEP_FIELDS.SERVICES]: {
            [SERVICES_FORM_FIELDS.OTHER]: isOtherSelected,
        },
    } = watch();

    const shouldRenderInput = !!isOtherSelected;

    return (
        <StepWrapper
            title={formatMessage({
                id: 'estimate-the-project.services-step.title',
            })}
            description={formatMessage({
                id: 'estimate-the-project.services-step.description',
            })}
        >
            <FormSection
                title={formatMessage({
                    id: 'estimate-the-project.services-step.services.title',
                })}
                helperText={formatMessage({
                    id: 'estimate-the-project.choose-one-or-more',
                })}
                error={errors[SERVICES_STEP_FIELDS.SERVICES]}
                isRequired
            >
                <OptionGrid
                    options={SERVICES_OPTIONS}
                    control={control}
                    fieldKey={SERVICES_STEP_FIELDS.SERVICES}
                    onChange={() => {
                        if (isSubmitted) {
                            trigger([
                                SERVICES_STEP_FIELDS.SERVICES,
                                SERVICES_STEP_FIELDS.OTHER_SERVICES_DESCRIPTION,
                            ]);
                        }
                    }}
                />
                {shouldRenderInput && (
                    <SInputContainer>
                        <Input
                            name={
                                SERVICES_STEP_FIELDS.OTHER_SERVICES_DESCRIPTION
                            }
                            control={control}
                            error={
                                errors[
                                    SERVICES_STEP_FIELDS
                                        .OTHER_SERVICE_DESCRIPTION
                                ]
                            }
                            as="textarea"
                        />
                    </SInputContainer>
                )}
            </FormSection>

            <Divider />

            <FormSection
                title={formatMessage({
                    id: 'estimate-the-project.services-step.scope.title',
                })}
                helperText={formatMessage({
                    id: 'estimate-the-project.choose-one-or-more',
                })}
                error={errors[SERVICES_STEP_FIELDS.SCOPE]}
                isRequired
            >
                <OptionGrid
                    options={SCOPE_OPTIONS}
                    control={control}
                    fieldKey={SERVICES_STEP_FIELDS.SCOPE}
                    colCount={1}
                    onChange={() => {
                        if (isSubmitted) {
                            trigger(SERVICES_STEP_FIELDS.SCOPE);
                        }
                    }}
                />
            </FormSection>
        </StepWrapper>
    );
};
