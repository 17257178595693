import React from 'react';
import { EstimateProjectView } from 'views/estimate-project';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/estimate-the-project.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const EstimateProject = (props) => {
    const { location } = props;
    return (
        <Layout location={location} messages={messages} navbar={false} fullPage>
            <EstimateProjectView />
        </Layout>
    );
};

EstimateProject.propTypes = {
    location: object.isRequired,
};

export default EstimateProject;

export const Head = () => <SEO tags={TAGS.ESTIMATE_THE_PROJECT} />;
