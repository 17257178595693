import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, number, object, shape, string } from 'prop-types';

import { OptionCheckbox } from '../option-checkbox';

const SContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ colCount }) => `repeat(${colCount}, 1fr)`};
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    ${CONSTANTS.MEDIA.max840`
        grid-template-columns: 1fr; 
    `}
`;

export const OptionGrid = ({
    options,
    control,
    onChange,
    fieldKey,
    colCount,
}) => {
    const { formatMessage } = useIntl();

    return (
        <SContainer colCount={colCount}>
            {options.map(({ translationKey, name }) => (
                <OptionCheckbox
                    key={name}
                    control={control}
                    title={formatMessage({
                        id: `${translationKey}.title`,
                    })}
                    description={formatMessage({
                        id: `${translationKey}.description`,
                    })}
                    name={`${fieldKey}.${name}`}
                    onChange={onChange}
                />
            ))}
        </SContainer>
    );
};

OptionGrid.propTypes = {
    options: arrayOf(
        shape({
            translationKey: string,
            name: string,
        }),
    ),
    control: object,
    fieldKey: string,
    colCount: number,
};

OptionGrid.defaultProps = {
    colCount: 2,
};
