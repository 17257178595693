import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider } from 'views/estimate-project/components/divider';
import { FormSection } from 'views/estimate-project/components/form-section';
import { OptionRadio } from 'views/estimate-project/components/option-radio';
import { StepWrapper } from 'views/estimate-project/components/step-wrapper';
import { CONSTANTS } from 'constants/styles/constants';

import {
    BUDGET_OPTIONS,
    BUDGET_STEP_FIELDS,
    TIMELINE_OPTIONS,
} from './_constants';

const SOptionGrid = styled.fieldset`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    ${CONSTANTS.MEDIA.max840`
        grid-template-columns: 1fr; 
    `}
`;

export const BudgetStep = () => {
    const { formatMessage } = useIntl();
    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <StepWrapper
            title={formatMessage({
                id: 'estimate-the-project.budget-step.title',
            })}
            description={formatMessage({
                id: 'estimate-the-project.budget-step.description',
            })}
        >
            <FormSection
                title={formatMessage({
                    id: 'estimate-the-project.budget-step.budget-range.title',
                })}
                error={errors[BUDGET_STEP_FIELDS.BUDGET_RANGE]}
                isRequired
            >
                <SOptionGrid id="budget">
                    {BUDGET_OPTIONS.map(({ translationKey, name }) => (
                        <OptionRadio
                            key={name}
                            value={name}
                            control={control}
                            title={formatMessage({
                                id: `${translationKey}.title`,
                            })}
                            description={formatMessage({
                                id: `${translationKey}.description`,
                            })}
                            name={BUDGET_STEP_FIELDS.BUDGET_RANGE}
                        />
                    ))}
                </SOptionGrid>
            </FormSection>

            <Divider />

            <FormSection
                title={formatMessage({
                    id: 'estimate-the-project.budget-step.timeline.title',
                })}
                error={errors[BUDGET_STEP_FIELDS.PROJECT_TIMELINE]}
                isRequired
            >
                <SOptionGrid id="timeline">
                    {TIMELINE_OPTIONS.map(({ translationKey, name }) => (
                        <OptionRadio
                            key={name}
                            value={name}
                            control={control}
                            title={formatMessage({
                                id: `${translationKey}.title`,
                            })}
                            description={formatMessage({
                                id: `${translationKey}.description`,
                            })}
                            name={BUDGET_STEP_FIELDS.PROJECT_TIMELINE}
                        />
                    ))}
                </SOptionGrid>
            </FormSection>
        </StepWrapper>
    );
};
