import React from 'react';
import styled from 'styled-components';
import { bool, node, string } from 'prop-types';

import { FormErrorMessage } from '../form-error-message';

const SHeader = styled.h3`
    font-size: 1rem;
    margin-bottom: ${({ smallGap }) => (smallGap ? '1rem' : '1.5rem')};
`;

const SSpan = styled.span`
    color: var(--primary-color);
`;

const SHelperText = styled.span`
    color: var(--gray-color-80);
    font-weight: 400;
`;

export const FormSection = ({
    children,
    isRequired,
    helperText,
    title,
    className,
    smallGap,
    error,
}) => {
    return (
        <div>
            <SHeader smallGap={smallGap}>
                {title}
                {isRequired && <SSpan aria-hidden={true}>*</SSpan>}
                {helperText && <SHelperText>{`  (${helperText})`}</SHelperText>}
            </SHeader>
            <div className={className}>{children}</div>
            {error?.message && <FormErrorMessage message={error.message} />}
        </div>
    );
};

FormSection.propTypes = {
    isRequired: bool,
    helperText: string,
    title: string.isRequired,
    children: node.isRequired,
    className: string,
    smallGap: bool,
    error: string,
};
