import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { any, func, node, object, oneOfType, string } from 'prop-types';

const SRadio = styled.input`
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--gray-color);
    accent-color: var(--primary-color);

    &:checked {
        border-color: var(--primary-color);
    }
`;

const SRadioWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 1.625rem;
`;

const STitle = styled.span`
    flex: 1;
    font-size: 1rem;
    padding-left: 0.75rem;
    cursor: pointer;
`;

const SContainer = styled.label`
    display: flex;
    cursor: pointer;
    border: 1px solid var(--gray-color);
    border-radius: 0.75rem;
    padding: 1rem;
    align-items: center;

    ${({ checked }) =>
        checked &&
        `
        border-color: var(--primary-color);
    `}
`;

export const TechSuggestionOption = ({
    name,
    control,
    label,
    className,
    value,
    onChange,
}) => {
    const { field } = useController({
        name,
        control,
        value,
    });

    const handleOnChange = () => {
        field.onChange(value);
        onChange?.();
    };

    return (
        <SContainer className={className} checked={field.value === value}>
            <SRadioWrapper>
                <SRadio
                    id={name}
                    type="radio"
                    defaultChecked={field.value === value}
                    {...field}
                    value={value}
                    onChange={handleOnChange}
                />
            </SRadioWrapper>
            <STitle>{label}</STitle>
        </SContainer>
    );
};

TechSuggestionOption.propTypes = {
    name: string.isRequired,
    control: object.isRequired,
    label: oneOfType([string, node]).isRequired,
    className: string,
    value: any,
    onChange: func,
};

TechSuggestionOption.defaultProps = {
    className: '',
};
