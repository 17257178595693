import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { bool, func, node, object, oneOfType, string } from 'prop-types';

const SCheckbox = styled.input`
    appearance: none;
    width: 1rem;
    height: 1rem;
    border-radius: 0.25rem;
    border: 1px solid var(--gray-color);
    cursor: pointer;

    &:checked {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.838' height='8.651' viewBox='0 0 8.838 8.651'%3E%3Cpath id='Path_2197' data-name='Path 2197' d='M-18725.863,2578.032-18723,2580l4.582-6.695' transform='translate(18726.43 -2572.741)' fill='none' stroke='%23f53103' stroke-width='2'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        border-color: var(--primary-color);
    }
`;

const SCheckboxWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 1.625rem;
`;

const STitle = styled.label`
    flex: 1;
    font-size: 1rem;
    cursor: pointer;
    padding-left: 0.75rem;
`;

const SDescription = styled(Paragraph)`
    font-size: 0.875rem;
    color: var(--gray-color-80);
    margin-top: 0.125rem;
`;

const SContainer = styled.div`
    display: flex;
`;

export const OptionCheckbox = ({
    name,
    control,
    title,
    description,
    disabled,
    className,
    isRequired,
    onChange,
}) => {
    const {
        field: { value, onChange: onFormChange },
    } = useController({
        name,
        control,
    });

    const handleChange = (e) => {
        onFormChange(!value);
        onChange?.(e);
    };

    return (
        <SContainer className={className}>
            <SCheckboxWrapper>
                <SCheckbox
                    id={name}
                    type="checkbox"
                    disabled={disabled}
                    checked={value}
                    aria-required={isRequired}
                    onChange={handleChange}
                />
            </SCheckboxWrapper>
            <STitle htmlFor={name}>
                {title}
                <SDescription htmlFor={name}>{description}</SDescription>
            </STitle>
        </SContainer>
    );
};

OptionCheckbox.propTypes = {
    name: string.isRequired,
    control: object.isRequired,
    title: oneOfType([string, node]).isRequired,
    description: oneOfType([string, node]).isRequired,
    className: string,
    disabled: bool,
    isRequired: bool,
    onChange: func,
};

OptionCheckbox.defaultProps = {
    className: '',
    disabled: false,
    isRequired: false,
};
