export const TECHNOLOGY_STEP_FIELDS = {
    TECHNOLOGY_STACK: 'technologyStack',
    THIRD_PARTY_INTEGRATIONS: 'thirdPartyIntegrations',
    OTHER_TECHNOLOGY_DESCRIPTION: 'otherTechnologyDescription',
    IS_OPEN_TO_SUGGESTIONS: 'isClientOpenToTechSuggestions',
};

export const TECHNOLOGY_FORM_FIELDS = {
    REACTJS: 'reactjs',
    NODEJS: 'nodejs',
    VUEJS: 'vuejs',
    FINTECH_APP_DEV: 'fintechAppDev',
    IOS: 'ios',
    ANDROID: 'android',
    AWS: 'aws',
    GCP: 'gcp',
    R: 'r',
    TABLEAU_POWERBI: 'tableauPowerbi',
    OTHER: 'other',
};

export const TECHNOLOGY_OPTIONS = [
    {
        name: TECHNOLOGY_FORM_FIELDS.REACTJS,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.reactjs',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.NODEJS,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.nodejs',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.VUEJS,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.vuejs',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.FINTECH_APP_DEV,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.fintech-app-dev',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.IOS,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.ios',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.ANDROID,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.android',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.AWS,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.aws',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.GCP,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.gcp',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.R,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.r',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.TABLEAU_POWERBI,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.tableau-powerbi',
    },
    {
        name: TECHNOLOGY_FORM_FIELDS.OTHER,
        translationKey:
            'estimate-the-project.technology-step.technology-stack.other',
    },
];

export const THIRD_PARTY_INTEGRATIONS_FORM_FIELDS = {
    PAYMENT_GATEWAYS: 'paymentGateways',
    SOCIAL_LOGINS: 'socialLogins',
    CRM_SYSTEMS: 'crmSystems',
    CUSTOM_INTEGRATIONS: 'customIntegrations',
};

export const THIRD_PARTY_INTEGRATIONS_OPTIONS = [
    {
        name: THIRD_PARTY_INTEGRATIONS_FORM_FIELDS.PAYMENT_GATEWAYS,
        translationKey:
            'estimate-the-project.technology-step.third-party-integrations.payment-gateways',
    },
    {
        name: THIRD_PARTY_INTEGRATIONS_FORM_FIELDS.SOCIAL_LOGINS,
        translationKey:
            'estimate-the-project.technology-step.third-party-integrations.social-logins',
    },
    {
        name: THIRD_PARTY_INTEGRATIONS_FORM_FIELDS.CRM_SYSTEMS,
        translationKey:
            'estimate-the-project.technology-step.third-party-integrations.crm-systems',
    },
    {
        name: THIRD_PARTY_INTEGRATIONS_FORM_FIELDS.CUSTOM_INTEGRATIONS,
        translationKey:
            'estimate-the-project.technology-step.third-party-integrations.custom-integrations',
    },
];

export const TECHNOLOGY_DEFAULT_VALUES = Object.fromEntries(
    Object.values(TECHNOLOGY_FORM_FIELDS).map((val) => [val, false]),
);

export const THIRD_PARTY_INTEGRATIONS_DEFAULT_VALUES = Object.fromEntries(
    Object.values(THIRD_PARTY_INTEGRATIONS_FORM_FIELDS).map((val) => [
        val,
        false,
    ]),
);

export const TECHNOLOGY_STEP_DEFAULT_VALUES = {
    [TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK]: TECHNOLOGY_DEFAULT_VALUES,
    [TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS]:
        THIRD_PARTY_INTEGRATIONS_DEFAULT_VALUES,
    [TECHNOLOGY_STEP_FIELDS.OTHER_TECHNOLOGY_DESCRIPTION]: '',
    [TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS]: null,
};
