import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
    PROGRESS_BAR_ITEM_STATUS,
    STEPS,
} from 'views/estimate-project/constants';
import { getCompletedSteps, getErrorSteps } from 'views/estimate-project/utils';
import { CONSTANTS } from 'constants/styles/constants';
import { func, number, string } from 'prop-types';

import { ProgressBarItem } from '../progress-bar-item';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 32.5rem;

    ${CONSTANTS.MEDIA.max840`
        height: auto;
        max-width: none;
        background: var(--white-color);
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 0.5rem;
    `}
`;

const SLine = styled.div`
    width: 1px;
    height: 2rem;
    background-color: ${({ isActive }) =>
        isActive ? 'var(--primary-color)' : 'var(--gray-color-70)'};
    margin-left: 3.25rem;

    ${CONSTANTS.MEDIA.max840`
        width: 0.75rem;
        height: 1px;
        margin-left: 0;
        margin-top: 4rem;
        flex-shrink: 0;
    `}
`;

export const ProgressBar = ({ currentStep, handleChangeStep, className }) => {
    const { formatMessage } = useIntl();
    const {
        watch,
        formState: { errors },
    } = useFormContext();

    // eslint-disable-next-line no-unused-vars
    const completedSteps = getCompletedSteps(watch());
    const errorSteps = getErrorSteps(errors);

    const resolveStatus = (index, id) => {
        if (currentStep === index + 1) {
            return PROGRESS_BAR_ITEM_STATUS.IN_PROGRESS;
        }

        if (errorSteps.includes(id)) {
            return PROGRESS_BAR_ITEM_STATUS.ERROR;
        }

        if (completedSteps.includes(id)) {
            return PROGRESS_BAR_ITEM_STATUS.COMPLETED;
        }

        return PROGRESS_BAR_ITEM_STATUS.TO_DO;
    };

    return (
        <SContainer className={className} role="tablist">
            {STEPS.map(({ id, title, icon }, index) => {
                return (
                    <Fragment key={id}>
                        <ProgressBarItem
                            title={formatMessage({
                                id: title,
                            })}
                            icon={icon}
                            status={resolveStatus(index, id)}
                            changeStep={() => handleChangeStep(index + 1)}
                        />
                        {index !== STEPS.length - 1 && (
                            <SLine isActive={index + 1 < currentStep} />
                        )}
                    </Fragment>
                );
            })}
        </SContainer>
    );
};

ProgressBar.propTypes = {
    currentStep: number.isRequired,
    handleChangeStep: func,
    className: string,
};
