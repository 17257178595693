import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { func, number } from 'prop-types';

import { ProgressBar } from '../progress-bar';

const SWrapper = styled.div`
    display: flex;
    align-items: stretch;
    width: calc(var(--dynamic-section-inner-width) * 0.35);
    position: relative;

    ${CONSTANTS.MEDIA.max840`
        width: 100%;
        overflow-x: scroll;
        height: auto;
        padding: 0.5rem 0;
    `}
`;

const SBanner = styled.div`
    position: fixed;
    background: var(--gray-color-90);
    height: calc(100vh - 6rem);
    margin-bottom: 1rem;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    padding-bottom: 4rem;
    width: inherit;

    ${CONSTANTS.MEDIA.max840`
        position: static;
        height: auto;
        max-width: none;
        background: var(--white-color);
        flex-direction: row;
        margin-bottom: 0;
        padding-bottom: 0;
    `}
`;

const Heading = styled(HeaderThird)`
    font-weight: 400;
    margin-bottom: 0.625rem;
    text-align: center;

    ${CONSTANTS.MEDIA.max840`
        display: none;
    `}
`;

const SParagraph = styled(Paragraph)`
    color: var(--gray-color-80);
    max-width: 18rem;
    text-align: center;
    margin-bottom: 2rem;

    ${CONSTANTS.MEDIA.max840`
        display: none;
    `}
`;

export const EstimateProjectOverlay = ({ currentStep, handleChangeStep }) => {
    return (
        <SWrapper>
            <SBanner>
                <Heading>
                    <FormattedMessage id="estimate-the-project.estimateYourProject" />
                </Heading>
                <SParagraph>
                    <FormattedMessage id="estimate-the-project.information" />
                </SParagraph>
                <ProgressBar
                    currentStep={currentStep}
                    handleChangeStep={handleChangeStep}
                />
            </SBanner>
        </SWrapper>
    );
};

EstimateProjectOverlay.propTypes = {
    handleChangeStep: func,
    currentStep: number,
};
