export const SERVICES_STEP_FIELDS = {
    SERVICES: 'services',
    SCOPE: 'scope',
    OTHER_SERVICES_DESCRIPTION: 'otherServicesDescription',
};

export const SERVICES_FORM_FIELDS = {
    CUSTOM_SOFTWARE_DEV: 'customSoftwareDevelopment',
    BLOCKCHAIN_DEV: 'blockchainDevelopment',
    WEB_DEV: 'webDevelopment',
    MOBILE_DEV: 'mobileDevelopment',
    PROPTECH_APP_DEV: 'proptechAppDevelopment',
    FINTECH_APP_DEV: 'fintechAppDevelopment',
    DATA_SCIENCE_ML_BI: 'dataScience',
    TEAM_AUGMENTATION: 'teamAugmentation',
    OTHER: 'other',
    NEW_DEVELOPMENT: 'newDevelopment',
};

export const SERVICES_OPTIONS = [
    {
        name: SERVICES_FORM_FIELDS.CUSTOM_SOFTWARE_DEV,
        translationKey:
            'estimate-the-project.services-step.services.custom-software-dev',
    },
    {
        name: SERVICES_FORM_FIELDS.BLOCKCHAIN_DEV,
        translationKey:
            'estimate-the-project.services-step.services.blockchain-dev',
    },
    {
        name: SERVICES_FORM_FIELDS.WEB_DEV,
        translationKey: 'estimate-the-project.services-step.services.web-dev',
    },
    {
        name: SERVICES_FORM_FIELDS.MOBILE_DEV,
        translationKey:
            'estimate-the-project.services-step.services.mobile-dev',
    },
    {
        name: SERVICES_FORM_FIELDS.PROPTECH_APP_DEV,
        translationKey:
            'estimate-the-project.services-step.services.proptech-dev',
    },
    {
        name: SERVICES_FORM_FIELDS.FINTECH_APP_DEV,
        translationKey:
            'estimate-the-project.services-step.services.fintech-dev',
    },
    {
        name: SERVICES_FORM_FIELDS.DATA_SCIENCE_ML_BI,
        translationKey:
            'estimate-the-project.services-step.services.data-science',
    },
    {
        name: SERVICES_FORM_FIELDS.TEAM_AUGMENTATION,
        translationKey:
            'estimate-the-project.services-step.services.team-augmentation',
    },
    {
        name: SERVICES_FORM_FIELDS.OTHER,
        translationKey: 'estimate-the-project.services-step.services.other',
    },
];

export const SCOPE_FORM_FIELDS = {
    NEW_DEVELOPMENT: 'newDevelopment',
    REDESIGN_UPGRADE: 'redesignOrUpgrade',
    FEATURE_ADDITION: 'featureAAddition',
    MAINTENANCE_SUPPORT: 'maintenanceAndSupport',
    CONSULTING: 'consulting',
};

export const SCOPE_OPTIONS = [
    {
        name: SCOPE_FORM_FIELDS.NEW_DEVELOPMENT,
        translationKey:
            'estimate-the-project.services-step.scope.new-development',
    },
    {
        name: SCOPE_FORM_FIELDS.REDESIGN_UPGRADE,
        translationKey:
            'estimate-the-project.services-step.scope.redesign-upgrade',
    },
    {
        name: SCOPE_FORM_FIELDS.FEATURE_ADDITION,
        translationKey:
            'estimate-the-project.services-step.scope.feature-addition',
    },
    {
        name: SCOPE_FORM_FIELDS.MAINTENANCE_SUPPORT,
        translationKey:
            'estimate-the-project.services-step.scope.maintenance-support',
    },
    {
        name: SCOPE_FORM_FIELDS.CONSULTING,
        translationKey: 'estimate-the-project.services-step.scope.consulting',
    },
];

export const SERVICES_DEFAULT_VALUES = Object.fromEntries(
    Object.values(SERVICES_FORM_FIELDS).map((val) => [val, false]),
);

export const SCOPE_DEFAULT_VALUES = Object.fromEntries(
    Object.values(SCOPE_FORM_FIELDS).map((val) => [val, false]),
);

export const SERVICES_STEP_DEFAULT_VALUES = {
    [SERVICES_STEP_FIELDS.SERVICES]: SERVICES_DEFAULT_VALUES,
    [SERVICES_STEP_FIELDS.SCOPE]: SCOPE_DEFAULT_VALUES,
    [SERVICES_STEP_FIELDS.OTHER_SERVICES_DESCRIPTION]: '',
};
