export const CONTACT_INFO_STEP_FIELDS = {
    FULL_NAME: 'name',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    COMPANY_NAME: 'companyName',
    ROLE_POSITION: 'role',
};

export const CONTACT_INFO_STEP_DEFAULT_VALUES = {
    [CONTACT_INFO_STEP_FIELDS.FULL_NAME]: '',
    [CONTACT_INFO_STEP_FIELDS.EMAIL]: '',
    [CONTACT_INFO_STEP_FIELDS.PHONE_NUMBER]: '',
    [CONTACT_INFO_STEP_FIELDS.COMPANY_NAME]: '',
    [CONTACT_INFO_STEP_FIELDS.ROLE_POSITION]: '',
};
