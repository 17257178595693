import React, { useReducer } from 'react';
import styled from 'styled-components';
import { EstimateProjectContext } from 'views/estimate-project/context';
import { Logo } from 'components/navbar/components/logo';
import { CONSTANTS } from 'constants/styles/constants';

import { EstimateProjectForm } from './components/estimate-form';
import {
    estimateProjectFormReducer,
    estimateProjectInitialState,
} from './components/reducer';

const SMain = styled.div`
    max-width: var(--section-max-width);
    width: 100%;
    margin: 0 auto;
    padding: 0 var(--section-clamp);
`;

const SEstimateProjectView = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 3.75rem;
    padding-top: 6rem;

    ${CONSTANTS.MEDIA.max840`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0;
        min-height: 100dvh;
    `}
`;

const SLogoWrapper = styled.div`
    height: 6rem;
    display: flex;
    align-items: center;
    position: fixed;
    background-color: var(--white-color);
    width: 100%;
    z-index: ${CONSTANTS.ZINDEX.HEADER};

    ${CONSTANTS.MEDIA.max1024`
        height: 5rem;
        margin-bottom: 2.25rem;
    `}
`;

export const EstimateProjectView = () => {
    const [state, dispatch] = useReducer(
        estimateProjectFormReducer,
        estimateProjectInitialState,
    );

    return (
        <EstimateProjectContext.Provider value={{ state, dispatch }}>
            <SMain>
                <SLogoWrapper>
                    <Logo />
                </SLogoWrapper>
                <SEstimateProjectView>
                    <EstimateProjectForm />
                </SEstimateProjectView>
            </SMain>
        </EstimateProjectContext.Provider>
    );
};
