import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { string } from 'prop-types';

const SError = styled(Paragraph)`
    font-size: 0.75rem;
    color: var(--primary-color);
    margin-top: 0.5rem;
    padding: 0 1rem;
`;

export const FormErrorMessage = ({ message }) => {
    return message ? <SError>{message}</SError> : null;
};

FormErrorMessage.propTypes = {
    message: string,
};

FormErrorMessage.defaultTypes = {
    message: '',
};
