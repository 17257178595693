import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FormSection } from 'views/estimate-project/components/form-section';
import { StepWrapper } from 'views/estimate-project/components/step-wrapper';
import { Input } from 'components/input-new';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

import { CONTACT_INFO_STEP_FIELDS } from './_constants';

const SFormSection = styled(FormSection)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SLink = styled.a`
    color: var(--primary-color);
`;

const SHorizontalWrapper = styled.div`
    display: flex;
    gap: 1rem;

    .input-container {
        flex: 1;
    }

    ${CONSTANTS.MEDIA.max840`
        flex-direction: column;
    `}
`;

export const ContactInfoStep = () => {
    const { formatMessage } = useIntl();
    const { control, formState } = useFormContext();

    return (
        <StepWrapper
            title={formatMessage({
                id: 'estimate-the-project.contact-info-step.title',
            })}
            description={
                <FormattedMessage
                    id="estimate-the-project.contact-info-step.description"
                    values={{
                        link: (chunks) => (
                            <SLink href={PATHS.CONTACT_US}>{chunks}</SLink>
                        ),
                    }}
                />
            }
        >
            <SFormSection
                title={formatMessage({
                    id: 'estimate-the-project.contact-info-step.your-info.title',
                })}
                smallGap
            >
                <Input
                    containerClassName="input-container"
                    name={CONTACT_INFO_STEP_FIELDS.FULL_NAME}
                    control={control}
                    label={formatMessage({
                        id: 'estimate-the-project.contact-info-step.your-info.full-name',
                    })}
                    error={formState.errors[CONTACT_INFO_STEP_FIELDS.FULL_NAME]}
                    isRequired
                />
                <SHorizontalWrapper>
                    <Input
                        containerClassName="input-container"
                        name={CONTACT_INFO_STEP_FIELDS.EMAIL}
                        control={control}
                        label={formatMessage({
                            id: 'estimate-the-project.contact-info-step.your-info.email',
                        })}
                        error={formState.errors[CONTACT_INFO_STEP_FIELDS.EMAIL]}
                        isRequired
                    />

                    <Input
                        containerClassName="input-container"
                        name={CONTACT_INFO_STEP_FIELDS.PHONE_NUMBER}
                        control={control}
                        label={formatMessage({
                            id: 'estimate-the-project.contact-info-step.your-info.phone-number',
                        })}
                        error={
                            formState.errors[
                                CONTACT_INFO_STEP_FIELDS.PHONE_NUMBER
                            ]
                        }
                    />
                </SHorizontalWrapper>
            </SFormSection>

            <SFormSection
                title={formatMessage({
                    id: 'estimate-the-project.contact-info-step.company-info.title',
                })}
                smallGap
            >
                <SHorizontalWrapper>
                    <Input
                        name={CONTACT_INFO_STEP_FIELDS.COMPANY_NAME}
                        containerClassName="input-container"
                        control={control}
                        label={formatMessage({
                            id: 'estimate-the-project.contact-info-step.company-info.company-name',
                        })}
                        error={
                            formState.errors[
                                CONTACT_INFO_STEP_FIELDS.COMPANY_NAME
                            ]
                        }
                    />
                    <Input
                        name={CONTACT_INFO_STEP_FIELDS.ROLE_POSITION}
                        containerClassName="input-container"
                        control={control}
                        label={formatMessage({
                            id: 'estimate-the-project.contact-info-step.company-info.role-position',
                        })}
                        error={
                            formState.errors[
                                CONTACT_INFO_STEP_FIELDS.ROLE_POSITION
                            ]
                        }
                    />
                </SHorizontalWrapper>
            </SFormSection>
        </StepWrapper>
    );
};
