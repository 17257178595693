import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PROGRESS_BAR_ITEM_STATUS } from 'views/estimate-project/constants';
import { CONSTANTS } from 'constants/styles/constants';
import { func, node, string } from 'prop-types';
import CheckIcon from 'svgs/icons/ic-check.svg';
import ErrorIcon from 'svgs/icons/ic-x.svg';

const statusStylesMap = {
    [PROGRESS_BAR_ITEM_STATUS.TO_DO]: {
        background: 'white',
        iconColor: 'var(--gray-color-80)',
        labelColor: 'var(--gray-color-80)',
        borderColor: 'white',
    },
    [PROGRESS_BAR_ITEM_STATUS.IN_PROGRESS]: {
        background: 'var(--primary-color)',
        iconColor: 'white',
        labelColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
    },
    [PROGRESS_BAR_ITEM_STATUS.COMPLETED]: {
        background: 'white',
        iconColor: 'var(--primary-color)',
        labelColor: 'var(--primary-color)',
        borderColor: 'var(--primary-color)',
    },
    [PROGRESS_BAR_ITEM_STATUS.ERROR]: {
        background: 'white',
        iconColor: 'var(--gray-color-80)',
        labelColor: 'var(--gray-color-80)',
        borderColor: 'var(--gray-color-80)',
    },
};

const SContainer = styled.button`
    display: flex;
    gap: 1rem;
    align-items: center;
    text-align: left;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background: var(--athens-gray-color);
    }

    ${CONSTANTS.MEDIA.max840`
      text-align: center;
      padding: 0.375rem;
      flex-direction: column; 
  `}
`;

const STitle = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: var(--black-text-color);

    ${CONSTANTS.MEDIA.max840`
      font-size: 0.875rem;
      text-wrap: nowrap;
      min-width: 5rem;
  `}
`;

const SStatus = styled.div`
    font-size: 0.875rem;
    color: ${({ $status }) => statusStylesMap[$status].labelColor};

    ${CONSTANTS.MEDIA.max840`
      font-size: 0.75rem;
      text-wrap: nowrap;
  `}
`;

const SIconContainer = styled.div`
    display: flex;
    font-size: 1.5rem;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    background: ${({ $status }) => statusStylesMap[$status].background};
    border: ${({ $status }) =>
        `2px solid ${statusStylesMap[$status].borderColor}`};
    color: ${({ $status }) => statusStylesMap[$status].iconColor};

    ${CONSTANTS.MEDIA.max840`
        width: 1.75rem;
        height: 1.75rem;

        * {
            width: 1.125rem;
            height: 1.125rem;
        }

        .check-icon, .error-icon {
            width: 1rem;
            height: 1rem;
        }
    `}
`;

export const ProgressBarItem = ({ title, icon, status, changeStep }) => {
    const { formatMessage } = useIntl();

    const renderIcon = () => {
        switch (status) {
            case PROGRESS_BAR_ITEM_STATUS.COMPLETED:
                return (
                    <CheckIcon width={18} height={18} className="check-icon" />
                );
            case PROGRESS_BAR_ITEM_STATUS.ERROR:
                return (
                    <ErrorIcon width={18} height={18} className="error-icon" />
                );
            default:
                return icon;
        }
    };

    return (
        <SContainer role="tab" onClick={() => changeStep()}>
            <SIconContainer className="icon-container" $status={status}>
                {renderIcon()}
            </SIconContainer>
            <div>
                <STitle>{title}</STitle>
                <SStatus $status={status}>
                    {formatMessage({
                        id: `estimate-the-project.${status}`,
                    })}
                </SStatus>
            </div>
        </SContainer>
    );
};

ProgressBarItem.propTypes = {
    title: string.isRequired,
    icon: node.isRequired,
    status: string.isRequired,
    onClick: func,
};
