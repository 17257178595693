export const removeFileExtension = (fileName) =>
    fileName.split('.').slice(0, -1).join('.');

export const toBase64 = (files) =>
    Promise.all(
        files.map(
            (image) =>
                new Promise((resolve, reject) => {
                    const fileReader = new FileReader();

                    fileReader.addEventListener('load', (file) => {
                        resolve(file.target.result.split(',')[1]);
                    });

                    fileReader.addEventListener('error', (event) => {
                        reject(event);
                    });

                    fileReader.readAsDataURL(image);
                }),
        ),
    );

export const resolveFormFiles = async (files, options) => {
    const base64Files = await toBase64(files);
    const { shouldRemoveExtension } = {
        shouldRemoveExtension: true,
        ...options,
    };

    return files.map((file, index) => ({
        content: base64Files[index],
        fileName: shouldRemoveExtension
            ? removeFileExtension(file.name)
            : file.name,
    }));
};

export const formatFileSize = (size) => {
    return `${Math.floor(size / 1_048_576)} MB`;
};
