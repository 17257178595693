import React, { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { Button } from 'components/button-new';
import { FormErrorLabel } from 'components/form-error-label';
import { MAX_TOTAL_FILE_SIZE } from 'constants/files';
import { number, object, string } from 'prop-types';
import FilesImage from 'svgs/images/files.svg';
import { formatFileSize } from 'utilities/files';

import { useFileUpload } from '../file-uploader/hooks';

import { FileItem } from './components/file-item';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const SDropFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.75rem 1rem;
    border: 1px dashed var(--gray-color-70);
    border-radius: 1rem;

    ${({ isDraggedOver }) =>
        isDraggedOver &&
        css`
            border-color: var(--primary-color);
            background-color: var(--primary-color-95);
        `}
`;

const SDropFieldContent = styled.div`
    max-width: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SFilesImage = styled(FilesImage)`
    margin-bottom: 1.25rem;
`;

const STitle = styled.div`
    font-size: 1rem;
    margin-bottom: 0.25rem;
    font-weight: 700;
    text-align: center;
`;

const SDescription = styled.div`
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    text-align: center;
`;

const SSubContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
`;

const SFilesContainer = styled.div`
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
`;

const SFileInput = styled.input`
    display: none;
`;

const SButton = styled(Button)`
    box-shadow: none;
`;

export const DropFileUploader = ({
    name,
    control,
    title,
    description,
    buttonLabel,
    error,
    containerClassName,
    allowedMaxFilesCount,
    allowedTotalFilesSize,
    allowedFileSize,
}) => {
    const { formatMessage } = useIntl();
    const [isDraggedOver, setIsDraggedOver] = useState(false);
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    });

    const onFileUploadError = () =>
        toast.error(formatMessage({ id: 'general.DropUploadError' }));
    const onExceedingFilesCount = () =>
        toast.error(
            formatMessage(
                { id: 'general.maxFilesCount' },
                { max_file_count: allowedMaxFilesCount },
            ),
        );
    const onExceedingFileSize = () =>
        toast.error(
            formatMessage(
                { id: 'general.maxFileSize' },
                {
                    max_file_size: formatFileSize(
                        allowedFileSize || allowedTotalFilesSize,
                    ),
                },
            ),
        );

    const onExceedingTotalFilesSize = () =>
        toast.error(
            formatMessage(
                { id: 'general.maxFilesSize' },
                { max_files_size: formatFileSize(allowedTotalFilesSize) },
            ),
        );

    const {
        inputFileRef,
        onAddFiles,
        onFileRemove,
        handleFileChange,
        handleFileDrop,
    } = useFileUpload({
        value,
        onChange,
        allowedMaxFilesCount,
        allowedTotalFilesSize,
        allowedFileSize,
        onFileUploadError,
        onExceedingFilesCount,
        onExceedingFileSize,
        onExceedingTotalFilesSize,
    });

    const resolveContent = useCallback(() => {
        return value.map(({ name, lastModified }, index) => (
            <FileItem
                key={`${name}-${lastModified}-${index}`}
                name={name}
                onRemove={onFileRemove(name)}
            />
        ));
    }, [value, onFileRemove]);

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDraggedOver(true);
    };

    const handleDragLeave = () => {
        setIsDraggedOver(false);
    };

    const handleDrop = (e) => {
        setIsDraggedOver(false);
        handleFileDrop(e);
    };

    return (
        <SContainer className={containerClassName}>
            <SDropFieldContainer
                isDraggedOver={isDraggedOver}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <SDropFieldContent>
                    <SFilesImage />
                    <STitle>{title}</STitle>
                    <SDescription>{description}</SDescription>
                    <SButton
                        variant="dark"
                        disabled={value.length === allowedMaxFilesCount}
                        onClick={onAddFiles}
                    >
                        {buttonLabel ||
                            formatMessage({
                                id: 'general.uploadFiles',
                            })}
                    </SButton>
                </SDropFieldContent>
            </SDropFieldContainer>
            <SSubContainer>
                <SFilesContainer>{resolveContent()}</SFilesContainer>
                <SFileInput
                    ref={inputFileRef}
                    type="file"
                    multiple
                    onChange={handleFileChange}
                />
            </SSubContainer>
            {error && <FormErrorLabel message={error?.message} />}
        </SContainer>
    );
};

DropFileUploader.propTypes = {
    name: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    buttonLabel: string.isRequired,
    control: object.isRequired,
    containerClassName: string,
    error: object,
    allowedMaxFilesCount: number,
    allowedTotalFilesSize: number,
    allowedFileSize: number,
};

DropFileUploader.defaultProps = {
    isRequired: false,
    allowedTotalFilesSize: MAX_TOTAL_FILE_SIZE,
};
