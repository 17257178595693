import { API_URL } from '../constants/api-url';
import { resolveFormFiles } from '../utilities/files';

import { requestConstructor } from './helpers';

const sendEstimationRequest = async (body, attachments) => {
    const requestBody = {
        ...body,
        attachments,
    };

    try {
        return await requestConstructor(API_URL.ESTIMATE, requestBody, false);
    } catch (err) {
        if (attachments.length === 0) {
            throw err; // Re-throw if there are no attachments
        }

        // Retry without attachments
        const requestBodyWithoutFiles = { ...body, attachments: [] };

        return requestConstructor(
            API_URL.ESTIMATE,
            requestBodyWithoutFiles,
            false,
        );
    }
};

export const estimateProjectMail = async (body) => {
    const attachments = await resolveFormFiles(body.attachments, {
        shouldRemoveExtension: false,
    });

    return sendEstimationRequest(body, attachments);
};
