import { yupResolver } from '@hookform/resolvers/yup';
import { ERROR_MESSAGE } from 'constants/form-constants';
import * as yup from 'yup';

import { ADDITIONAL_INFO_STEP_FIELDS } from '../steps/additional-info-step/_constants';
import { BUDGET_STEP_FIELDS } from '../steps/budget-step/_constants';
import { CONTACT_INFO_STEP_FIELDS } from '../steps/contact-info-step/_constants';
import {
    SERVICES_FORM_FIELDS,
    SERVICES_STEP_FIELDS,
} from '../steps/services-step/_constants';
import {
    TECHNOLOGY_FORM_FIELDS,
    TECHNOLOGY_STEP_FIELDS,
} from '../steps/technology-step/_constants';

const getFieldObject = (fields) => {
    return fields.reduce((acc, field) => {
        acc[field] = yup.boolean();
        return acc;
    }, {});
};

const validateAtLeastOneSelected = (obj, errorMessage, fieldPath) => {
    if (Object.values(obj).some(Boolean)) {
        return true;
    }

    return new yup.ValidationError(errorMessage, null, fieldPath);
};

export const estimateProjectValidationSchema = yupResolver(
    yup.object().shape({
        // CONTACT INFO STEP
        [CONTACT_INFO_STEP_FIELDS.FULL_NAME]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD)
            .max(100, ERROR_MESSAGE.MAX_CHARS(100)),
        [CONTACT_INFO_STEP_FIELDS.EMAIL]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD)
            .email(ERROR_MESSAGE.EMAIL)
            .max(100, ERROR_MESSAGE.MAX_CHARS(100)),
        [CONTACT_INFO_STEP_FIELDS.PHONE_NUMBER]: yup.string().matches(
            // eslint-disable-next-line no-useless-escape
            /^((1[ +\-]{0,3}|\+1[ -+]{0,3}|\+1|\+)?((\(\+?1-[2-9]\d{1,2}\))|(\(\+?[2-8]\d{2}\))|(\(\+?[1-9]\d\))|(\(\+?[17]\))|(\([2-9]{2}\))|([ .\-]{0,3}\d{2,4}))?([ .\-]\d)?([ .\-]{0,3}\d{2,4}){2,3})?$/,
            ERROR_MESSAGE.PHONE,
        ),
        [CONTACT_INFO_STEP_FIELDS.COMPANY_NAME]: yup
            .string()
            .max(100, ERROR_MESSAGE.MAX_CHARS(100)),
        [CONTACT_INFO_STEP_FIELDS.ROLE_POSITION]: yup
            .string()
            .max(100, ERROR_MESSAGE.MAX_CHARS(100)),

        // SERVICES STEP
        [SERVICES_STEP_FIELDS.SERVICES]: yup
            .object()
            .shape(getFieldObject(Object.values(SERVICES_FORM_FIELDS)))
            .test('validateAtLeastOneSelected', null, (obj) =>
                validateAtLeastOneSelected(
                    obj,
                    ERROR_MESSAGE.SELECT_ONE,
                    SERVICES_STEP_FIELDS.SERVICES,
                ),
            ),
        [SERVICES_STEP_FIELDS.SCOPE]: yup
            .object()
            .shape(getFieldObject(Object.values(SERVICES_FORM_FIELDS)))
            .test('validateAtLeastOneSelected', null, (obj) =>
                validateAtLeastOneSelected(
                    obj,
                    ERROR_MESSAGE.SELECT_ONE,
                    SERVICES_STEP_FIELDS.SCOPE,
                ),
            ),

        [SERVICES_STEP_FIELDS.OTHER_SERVICES_DESCRIPTION]: yup
            .string()
            .when(SERVICES_STEP_FIELDS.SERVICES, ([services]) => {
                if (services && services[SERVICES_FORM_FIELDS.OTHER]) {
                    return yup
                        .string()
                        .required(ERROR_MESSAGE.REQUIRED_FIELD)
                        .max(500, ERROR_MESSAGE.MAX_CHARS(500));
                }
                return yup.string();
            }),

        // TECHNOLOGY STEP
        [TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS]: yup
            .boolean()
            .required(ERROR_MESSAGE.SELECT_ONE),
        [TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK]: yup
            .object()
            .shape(getFieldObject(Object.values(TECHNOLOGY_FORM_FIELDS)))
            .when(
                TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS,
                ([isClientOpenToTechSuggestions], schema) => {
                    if (isClientOpenToTechSuggestions === false) {
                        return schema.test(
                            'validateAtLeastOneSelected',
                            null,
                            (obj) =>
                                validateAtLeastOneSelected(
                                    obj,
                                    ERROR_MESSAGE.SELECT_ONE,
                                    TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK,
                                ),
                        );
                    }

                    return schema;
                },
            ),
        [TECHNOLOGY_STEP_FIELDS.OTHER_TECHNOLOGY_DESCRIPTION]: yup
            .string()
            .when(
                [
                    TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK,
                    TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS,
                ],
                ([technologies, isClientOpenToTechSuggestions]) => {
                    if (
                        isClientOpenToTechSuggestions === false &&
                        technologies &&
                        technologies[TECHNOLOGY_FORM_FIELDS.OTHER]
                    ) {
                        return yup
                            .string()
                            .required(ERROR_MESSAGE.REQUIRED_FIELD)
                            .max(500, ERROR_MESSAGE.MAX_CHARS(500));
                    }
                    return yup.string();
                },
            ),
        [TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS]: yup
            .object()
            .shape(getFieldObject(Object.values(TECHNOLOGY_FORM_FIELDS)))
            .when(
                TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS,
                ([isClientOpenToTechSuggestions], schema) => {
                    if (isClientOpenToTechSuggestions === false) {
                        return schema.test(
                            'validateAtLeastOneSelected',
                            null,
                            (obj) =>
                                validateAtLeastOneSelected(
                                    obj,
                                    ERROR_MESSAGE.SELECT_ONE,
                                    TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS,
                                ),
                        );
                    }

                    return schema;
                },
            ),

        // BUDGET STEP
        [BUDGET_STEP_FIELDS.BUDGET_RANGE]: yup
            .string()
            .required(ERROR_MESSAGE.SELECT_ONE),
        [BUDGET_STEP_FIELDS.PROJECT_TIMELINE]: yup
            .string()
            .required(ERROR_MESSAGE.SELECT_ONE),

        // ADDITIONAL INFO STEP
        [ADDITIONAL_INFO_STEP_FIELDS.ADDITIONAL_DETAILS]: yup
            .string()
            .max(2000, ERROR_MESSAGE.MAX_CHARS(2000)),
        [ADDITIONAL_INFO_STEP_FIELDS.PRIVACY_POLICY]: yup.bool(),
        [ADDITIONAL_INFO_STEP_FIELDS.PRIVACY_POLICY]: yup
            .bool()
            .oneOf([true], ERROR_MESSAGE.PRIVACY_POLICY),
        [ADDITIONAL_INFO_STEP_FIELDS.ATTACHMENTS]: yup.array(),
    }),
);
