import React, { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import {
    customDialogContent,
    ESTIMATE_PROJECT_FORM_DEFAULT_VALUES,
    GA_TRACKED_STEP_EVENTS_LIST,
} from 'views/estimate-project/constants';
import { AdditionalInfoStep } from 'views/estimate-project/steps/additional-info-step';
import { ADDITIONAL_INFO_STEP_FIELDS } from 'views/estimate-project/steps/additional-info-step/_constants';
import { BudgetStep } from 'views/estimate-project/steps/budget-step';
import {
    BUDGET_OPTIONS,
    BUDGET_STEP_FIELDS,
    TIMELINE_OPTIONS,
} from 'views/estimate-project/steps/budget-step/_constants';
import { ContactInfoStep } from 'views/estimate-project/steps/contact-info-step';
import { CONTACT_INFO_STEP_FIELDS } from 'views/estimate-project/steps/contact-info-step/_constants';
import { ServicesStep } from 'views/estimate-project/steps/services-step';
import {
    SCOPE_OPTIONS,
    SERVICES_FORM_FIELDS,
    SERVICES_OPTIONS,
    SERVICES_STEP_FIELDS,
} from 'views/estimate-project/steps/services-step/_constants';
import { TechnologyStep } from 'views/estimate-project/steps/technology-step';
import {
    TECHNOLOGY_FORM_FIELDS,
    TECHNOLOGY_OPTIONS,
    TECHNOLOGY_STEP_FIELDS,
    THIRD_PARTY_INTEGRATIONS_OPTIONS,
} from 'views/estimate-project/steps/technology-step/_constants';
import { hasStepError } from 'views/estimate-project/utils';
import { estimateProjectMail } from 'api/estimate';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { Overlay } from 'components/overlay';
import { FORM_STATUSES } from 'constants/form-statuses';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { GTAG_CONSTANTS } from 'constants/gtag-constants';
import { CONSTANTS } from 'constants/styles/constants';
import { useLoading } from 'hooks/use-loading';
import { useReCaptcha } from 'hooks/use-re-captcha';
import {
    gtagReportConversion,
    trackCustomEventGoogleAnalytics,
    trackLinkedInCampaign,
} from 'utilities/index';

import { estimateProjectValidationSchema } from '../../validation';
import { Buttons } from '../buttons';
import { EstimateProjectOverlay } from '../overlay';

const SForm = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: calc(var(--dynamic-section-inner-width) * 0.65 - 3rem);
    flex: 1;

    ${CONSTANTS.MEDIA.max840`
        padding: 0;
        max-width: none;
        min-height: auto;
        width: 100%;
    `}
`;

const SContainer = styled.div`
    width: 100%;
    position: relative;
    min-height: 45rem;
    padding-left: 3rem;
    padding-bottom: 3.5rem;
    flex: 1;

    ${CONSTANTS.MEDIA.max840`
        display: flex;
        flex-direction: column;
        padding: 0;
        max-width: none;
        min-height: auto;
    `}
`;

const SButtons = styled(Buttons)`
    position: fixed;
    bottom: 0;
    width: inherit;

    ${CONSTANTS.MEDIA.max840`
      position: static;
        max-width: none;
        width: 100%;
    `}
`;

const SEstimateProjectForm = styled.div`
    margin-top: 1.5rem;
    width: 100%;

    ${CONSTANTS.MEDIA.max840`
      display: flex;
      margin-top: 2rem;
      flex: 1;
    `}
`;

export const EstimateProjectForm = () => {
    const dialogRef = useRef();

    const methods = useForm({
        resolver: estimateProjectValidationSchema,
        defaultValues: ESTIMATE_PROJECT_FORM_DEFAULT_VALUES,
        reValidateMode: 'onChange',
    });
    const [currentStep, setCurrentStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState(new Set());
    const { isLoading, loadingStart, loadingStop } = useLoading();
    const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);
    const { handleSubmit, isDirty, reset } = methods;
    const { formatMessage } = useIntl();

    const getOptionString = (options, id) => {
        const option = options.find((option) => option.name === id);

        return formatMessage({
            id: `${option.translationKey}.title`,
        });
    };

    const getOptionList = (options, selectedOptions) => {
        const selected = Object.entries(selectedOptions).filter(
            // eslint-disable-next-line no-unused-vars
            ([_, value]) => value,
        );

        return selected.map(([key]) => {
            return getOptionString(options, key);
        });
    };

    const onFormSubmit = useCallback((status) => {
        setFormStatus(status);

        if (status !== FORM_STATUSES.IDLE) {
            dialogRef?.current?.showModal();
        }
    }, []);

    const onFailedLoad = () =>
        onFormSubmit(FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR);

    const { requestRecaptchaValidation } = useReCaptcha({
        isDirty,
        onFailedLoad,
    });

    const onSubmit = async (data) => {
        loadingStart();

        try {
            const token = await requestRecaptchaValidation();
            const {
                name,
                email,
                phoneNumber,
                companyName,
                role,
                services,
                scope,
                otherServicesDescription,
                technologyStack,
                thirdPartyIntegrations,
                otherTechnologyDescription,
                isClientOpenToTechSuggestions,
                budgetRange,
                projectTimeline,
                nda,
                additionalDetails,
                attachments,
            } = data;

            const parseDescription = (field, condition) =>
                condition ? field : undefined;

            const parsedOtherTechnologyDescription = parseDescription(
                otherTechnologyDescription,
                technologyStack[TECHNOLOGY_FORM_FIELDS.OTHER],
            );

            const parsedOtherServicesDescription = parseDescription(
                otherServicesDescription,
                services[SERVICES_FORM_FIELDS.OTHER],
            );

            await estimateProjectMail({
                // Contact Info
                name,
                companyName,
                email,
                phoneNumber: phoneNumber || undefined,
                role,
                // Services
                services: getOptionList(SERVICES_OPTIONS, services),
                otherServicesDescription: parsedOtherServicesDescription,
                scope: getOptionList(SCOPE_OPTIONS, scope),
                // Technology
                isClientOpenToTechSuggestions,
                ...(!isClientOpenToTechSuggestions && {
                    techStack: getOptionList(
                        TECHNOLOGY_OPTIONS,
                        technologyStack,
                    ),
                    otherStackDescription: parsedOtherTechnologyDescription,
                    thirdPartyIntegrations: getOptionList(
                        THIRD_PARTY_INTEGRATIONS_OPTIONS,
                        thirdPartyIntegrations,
                    ),
                }),
                // Budget
                budget: getOptionString(BUDGET_OPTIONS, budgetRange),
                timeline: getOptionString(TIMELINE_OPTIONS, projectTimeline),
                // Additional Info
                additionalDetails,
                sendNda: nda,
                attachments,

                recaptchaResponse: token,
            });

            trackLinkedInCampaign();
            trackCustomEventGoogleAnalytics(
                gaTrackedEvents.ESTIMATE_PROJECT.STEP_FOURTH,
            );
            trackCustomEventGoogleAnalytics(
                gaTrackedEvents.SALES.SALES_ESTIMATION_FORM,
            );
            gtagReportConversion(GTAG_CONSTANTS.ESTIMATE_PROJECT_FORM);

            onFormSubmit(FORM_STATUSES.SUCCESS);
            reset();
        } catch {
            onFormSubmit(FORM_STATUSES.ERROR);
        } finally {
            loadingStop();
        }
    };

    const onSubmitError = (errors) => {
        switch (true) {
            case hasStepError(CONTACT_INFO_STEP_FIELDS, errors):
                return setCurrentStep(1);
            case hasStepError(SERVICES_STEP_FIELDS, errors):
                return setCurrentStep(2);
            case hasStepError(TECHNOLOGY_STEP_FIELDS, errors):
                return setCurrentStep(3);
            case hasStepError(BUDGET_STEP_FIELDS, errors):
                return setCurrentStep(4);
            case hasStepError(ADDITIONAL_INFO_STEP_FIELDS, errors):
                return setCurrentStep(5);
            default:
        }
    };

    const onClose = () => {
        if (formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR) {
            window.location.reload();
            return;
        }

        dialogRef?.current?.close();
        setFormStatus(FORM_STATUSES.IDLE);
    };

    const renderCurrentStep = (step) => {
        switch (step) {
            case 1: {
                return <ContactInfoStep />;
            }
            case 2: {
                return <ServicesStep />;
            }
            case 3: {
                return <TechnologyStep />;
            }
            case 4: {
                return <BudgetStep />;
            }
            case 5: {
                return <AdditionalInfoStep />;
            }
            default: {
                return <div />;
            }
        }
    };

    const handleCompleteStep = (step) => {
        setCompletedSteps((prev) => new Set([...prev, step]));
    };

    const handleChangeStep = (step) => {
        const gaEvent = GA_TRACKED_STEP_EVENTS_LIST[step - 1];

        window.scrollTo(0, 0);
        setCurrentStep(step);

        if (gaEvent) {
            trackCustomEventGoogleAnalytics(gaEvent);
        }
    };

    return (
        <FormProvider {...methods}>
            <Overlay show={isLoading} />
            <EstimateProjectOverlay
                currentStep={currentStep}
                handleChangeStep={handleChangeStep}
                completedSteps={completedSteps}
                completeStep={handleCompleteStep}
                handleSubmit={handleSubmit(onSubmit, onSubmitError)}
            />
            <SContainer>
                <SEstimateProjectForm id="estimation-form">
                    <SForm id="estimate-project-form">
                        {renderCurrentStep(currentStep)}
                        <SButtons
                            currentStep={currentStep}
                            handleSubmit={handleSubmit(onSubmit, onSubmitError)}
                            handleChangeStep={handleChangeStep}
                            completeStep={handleCompleteStep}
                        />
                    </SForm>
                </SEstimateProjectForm>
            </SContainer>

            <Dialog ref={dialogRef} testId="form_popup">
                <DialogContent
                    {...resolveDialogContent({
                        formStatus,
                        customDialogContent,
                    })}
                    onClose={onClose}
                />
            </Dialog>
        </FormProvider>
    );
};
