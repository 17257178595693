export const BUDGET_STEP_FIELDS = {
    BUDGET_RANGE: 'budgetRange',
    PROJECT_TIMELINE: 'projectTimeline',
};

export const BUDGET_FORM_FIELDS = {
    UNDER_10000: 'under10000',
    FROM_10000_TO_25000: 'from10000To25000',
    FROM_25000_TO_50000: 'from25000To50000',
    FROM_50000_TO_100000: 'from50000To100000',
    OVER_100000: 'over100000',
    NOT_SURE: 'notSure',
};

export const BUDGET_OPTIONS = [
    {
        name: BUDGET_FORM_FIELDS.UNDER_10000,
        translationKey:
            'estimate-the-project.budget-step.budget-range.under-10000',
    },
    {
        name: BUDGET_FORM_FIELDS.FROM_10000_TO_25000,
        translationKey:
            'estimate-the-project.budget-step.budget-range.10000-25000',
    },
    {
        name: BUDGET_FORM_FIELDS.FROM_25000_TO_50000,
        translationKey:
            'estimate-the-project.budget-step.budget-range.25000-50000',
    },
    {
        name: BUDGET_FORM_FIELDS.FROM_50000_TO_100000,
        translationKey:
            'estimate-the-project.budget-step.budget-range.50000-100000',
    },
    {
        name: BUDGET_FORM_FIELDS.OVER_100000,
        translationKey:
            'estimate-the-project.budget-step.budget-range.over-100000',
    },
    {
        name: BUDGET_FORM_FIELDS.NOT_SURE,
        translationKey:
            'estimate-the-project.budget-step.budget-range.not-sure',
    },
];

export const TIMELINE_FORM_FIELDS = {
    LESS_THAN_2_MONTHS: 'lessThan2Months',
    FROM_2_TO_4_MONTHS: 'from2To4Months',
    FROM_4_TO_6_MONTHS: 'from4To6Months',
    OVER_6_MONTHS: 'over6Months',
    FLEXIBLE_NOT_SURE: 'flexibleNotSure',
};

export const TIMELINE_OPTIONS = [
    {
        name: TIMELINE_FORM_FIELDS.LESS_THAN_2_MONTHS,
        translationKey:
            'estimate-the-project.budget-step.timeline.less-than-2-months',
    },
    {
        name: TIMELINE_FORM_FIELDS.FROM_2_TO_4_MONTHS,
        translationKey: 'estimate-the-project.budget-step.timeline.2-4-months',
    },
    {
        name: TIMELINE_FORM_FIELDS.FROM_4_TO_6_MONTHS,
        translationKey: 'estimate-the-project.budget-step.timeline.4-6-months',
    },
    {
        name: TIMELINE_FORM_FIELDS.OVER_6_MONTHS,
        translationKey:
            'estimate-the-project.budget-step.timeline.over-6-months',
    },
    {
        name: TIMELINE_FORM_FIELDS.FLEXIBLE_NOT_SURE,
        translationKey:
            'estimate-the-project.budget-step.timeline.flexible-not-sure',
    },
];

export const BUDGET_STEP_DEFAULT_VALUES = {
    [BUDGET_STEP_FIELDS.BUDGET_RANGE]: '',
    [BUDGET_STEP_FIELDS.PROJECT_TIMELINE]: '',
};
