import React from 'react';
import { DIALOG_CONTENT } from 'components/dialog/constants';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import CodeBoldIcon from 'svgs/icons/ic-code-bold.svg';
import EmptyWalletIcon from 'svgs/icons/ic-empty-wallet.svg';
import MessageAddSvg from 'svgs/icons/ic-message-add.svg';
import WebsiteIcon from 'svgs/icons/ic-monitor.svg';
import SmsIcon from 'svgs/icons/ic-sms.svg';

import {
    ADDITIONAL_INFO_STEP_DEFAULT_VALUES,
    ADDITIONAL_INFO_STEP_FIELDS,
} from '../steps/additional-info-step/_constants';
import {
    BUDGET_STEP_DEFAULT_VALUES,
    BUDGET_STEP_FIELDS,
} from '../steps/budget-step/_constants';
import {
    CONTACT_INFO_STEP_DEFAULT_VALUES,
    CONTACT_INFO_STEP_FIELDS,
} from '../steps/contact-info-step/_constants';
import {
    SERVICES_STEP_DEFAULT_VALUES,
    SERVICES_STEP_FIELDS,
} from '../steps/services-step/_constants';
import {
    TECHNOLOGY_STEP_DEFAULT_VALUES,
    TECHNOLOGY_STEP_FIELDS,
} from '../steps/technology-step/_constants';

export const STEP = {
    CONTACT_INFO_STEP: 'contactInfoStep',
    SERVICES_STEP: 'servicesStep',
    TECHNOLOGY_STEP: 'technologyStep',
    BUDGET_STEP: 'budgetStep',
    ADDITIONAL_INFO_STEP: 'additionalInfoStep',
};

export const MAX_ESTIMATION_FILE_SIZE = 2_097_152; // 2MB

export const STEP_FIELDS_MAP = {
    [STEP.CONTACT_INFO_STEP]: Object.values(CONTACT_INFO_STEP_FIELDS),
    [STEP.SERVICES_STEP]: Object.values(SERVICES_STEP_FIELDS),
    [STEP.TECHNOLOGY_STEP]: Object.values(TECHNOLOGY_STEP_FIELDS),
    [STEP.BUDGET_STEP]: Object.values(BUDGET_STEP_FIELDS),
    [STEP.ADDITIONAL_INFO_STEP]: Object.values(ADDITIONAL_INFO_STEP_FIELDS),
};
// Make the values arrays not objects

export const GA_TRACKED_STEP_EVENTS_LIST = Object.values(
    gaTrackedEvents.ESTIMATE_PROJECT,
);

export const PROGRESS_BAR_ITEM_STATUS = {
    COMPLETED: 'completed',
    IN_PROGRESS: 'inProgress',
    TO_DO: 'toDo',
    ERROR: 'error',
};

export const ESTIMATE_PROJECT_FORM_FIELDS = {
    ...CONTACT_INFO_STEP_FIELDS,
    ...SERVICES_STEP_FIELDS,
    ...TECHNOLOGY_STEP_FIELDS,
    ...BUDGET_STEP_FIELDS,
    ...ADDITIONAL_INFO_STEP_FIELDS,
};

export const ESTIMATE_PROJECT_FORM_DEFAULT_VALUES = {
    ...CONTACT_INFO_STEP_DEFAULT_VALUES,
    ...SERVICES_STEP_DEFAULT_VALUES,
    ...TECHNOLOGY_STEP_DEFAULT_VALUES,
    ...BUDGET_STEP_DEFAULT_VALUES,
    ...ADDITIONAL_INFO_STEP_DEFAULT_VALUES,
};

export const STEPS = [
    {
        id: STEP.CONTACT_INFO_STEP,
        title: 'estimate-the-project.contact-info-step.title',
        icon: <SmsIcon width={24} height={24} />,
    },
    {
        id: STEP.SERVICES_STEP,
        title: 'estimate-the-project.services-step.title',
        icon: <WebsiteIcon width={24} height={24} />,
    },
    {
        id: STEP.TECHNOLOGY_STEP,
        title: 'estimate-the-project.technology-step.title',
        icon: <CodeBoldIcon width={24} height={24} />,
    },
    {
        id: STEP.BUDGET_STEP,
        title: 'estimate-the-project.budget-step.title',
        icon: <EmptyWalletIcon width={24} height={24} />,
    },
    {
        id: STEP.ADDITIONAL_INFO_STEP,
        title: 'estimate-the-project.additional-info-step.title',
        icon: <MessageAddSvg width={24} height={24} />,
    },
];

export const customDialogContent = {
    error: {
        ...DIALOG_CONTENT.ERROR,
        info: 'estimate-the-project.dialog.error',
        description: '',
    },
};

export const ESTIMATE_PROJECT_STEP_COUNT = Object.entries(STEP).length;
