import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const SDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--gray-color-70);
`;

export const Divider = ({ className }) => {
    return <SDivider className={className} />;
};

Divider.propTypes = {
    className: string,
};
