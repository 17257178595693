import React from 'react';
import { useController } from 'react-hook-form';
import styled from 'styled-components';
import { Paragraph } from 'components/paragraph';
import { node, object, oneOfType, string } from 'prop-types';

const SRadio = styled.input`
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--gray-color);
    accent-color: var(--primary-color);

    &:checked {
        border-color: var(--primary-color);
    }
`;

const SRadioWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 1.625rem;
`;

const STitle = styled.span`
    flex: 1;
    font-size: 1rem;
    padding-left: 0.75rem;
    cursor: pointer;
`;

const SDescription = styled(Paragraph)`
    font-size: 0.875rem;
    color: var(--gray-color-80);
    margin-top: 0.125rem;
`;

const SLabel = styled.label`
    display: flex;
    cursor: pointer;
`;

export const OptionRadio = ({
    name,
    control,
    title,
    description,
    className,
    value,
}) => {
    const { field } = useController({
        name,
        control,
        value,
    });

    return (
        <SLabel className={className}>
            <SRadioWrapper>
                <SRadio
                    id={name}
                    type="radio"
                    defaultChecked={field.value === value}
                    {...field}
                    value={value}
                />
            </SRadioWrapper>
            <STitle>
                {title}
                <SDescription>{description}</SDescription>
            </STitle>
        </SLabel>
    );
};

OptionRadio.propTypes = {
    name: string.isRequired,
    control: object.isRequired,
    title: oneOfType([string, node]).isRequired,
    description: oneOfType([string, node]).isRequired,
    className: string,
};

OptionRadio.defaultProps = {
    className: '',
    disabled: false,
    isRequired: false,
};
