import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Divider } from 'views/estimate-project/components/divider';
import { FormSection } from 'views/estimate-project/components/form-section';
import { OptionGrid } from 'views/estimate-project/components/option-grid';
import { StepWrapper } from 'views/estimate-project/components/step-wrapper';
import { Input } from 'components/input-new';
import { CONSTANTS } from 'constants/styles/constants';

import { TechSuggestionOption } from './_components/tech-suggestion-option';
import {
    TECHNOLOGY_FORM_FIELDS,
    TECHNOLOGY_OPTIONS,
    TECHNOLOGY_STEP_FIELDS,
    THIRD_PARTY_INTEGRATIONS_OPTIONS,
} from './_constants';

const SInputContainer = styled.div`
    margin-top: 1rem;
    margin-left: 1.625rem;
`;

const STechSuggestionsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 0.5rem;

    ${CONSTANTS.MEDIA.max840`
        grid-template-columns: 1fr;
    `}
`;

export const TechnologyStep = () => {
    const { formatMessage } = useIntl();
    const {
        formState: { errors, isSubmitted },
        control,
        watch,
        trigger,
    } = useFormContext();

    const {
        [TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK]: {
            [TECHNOLOGY_FORM_FIELDS.OTHER]: isOtherSelected,
        },
        [TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS]:
            isClientOpenToTechSuggestions,
    } = watch();

    const handleSuggestionsChange = () => {
        if (isSubmitted) {
            trigger([
                TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS,
                TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK,
                TECHNOLOGY_STEP_FIELDS.OTHER_TECHNOLOGY_DESCRIPTION,
                TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS,
            ]);
        }
    };

    return (
        <StepWrapper
            title={formatMessage({
                id: 'estimate-the-project.technology-step.title',
            })}
            description={formatMessage({
                id: 'estimate-the-project.technology-step.description',
            })}
        >
            <FormSection
                title={formatMessage({
                    id: 'estimate-the-project.technology-step.suggestions.question',
                })}
                error={errors[TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS]}
                isRequired
            >
                <STechSuggestionsWrapper>
                    <TechSuggestionOption
                        name={TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS}
                        value={false}
                        label={formatMessage({
                            id: 'estimate-the-project.technology-step.suggestions.options.have-preferences',
                        })}
                        control={control}
                        onChange={handleSuggestionsChange}
                    />
                    <TechSuggestionOption
                        name={TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS}
                        value={true}
                        label={formatMessage({
                            id: 'estimate-the-project.technology-step.suggestions.options.open-to-suggestions',
                        })}
                        control={control}
                        onChange={handleSuggestionsChange}
                    />
                </STechSuggestionsWrapper>
            </FormSection>

            {isClientOpenToTechSuggestions === false && (
                <>
                    <Divider />

                    <FormSection
                        title={formatMessage({
                            id: 'estimate-the-project.technology-step.technology-stack.title',
                        })}
                        helperText={formatMessage({
                            id: 'estimate-the-project.select-all-that-apply',
                        })}
                        error={errors[TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK]}
                        isRequired
                    >
                        <OptionGrid
                            options={TECHNOLOGY_OPTIONS}
                            control={control}
                            fieldKey={TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK}
                            onChange={() => {
                                if (isSubmitted) {
                                    trigger([
                                        TECHNOLOGY_STEP_FIELDS.TECHNOLOGY_STACK,
                                        TECHNOLOGY_STEP_FIELDS.OTHER_TECHNOLOGY_DESCRIPTION,
                                    ]);
                                }
                            }}
                        />
                        {isOtherSelected && (
                            <SInputContainer>
                                <Input
                                    name={
                                        TECHNOLOGY_STEP_FIELDS.OTHER_TECHNOLOGY_DESCRIPTION
                                    }
                                    control={control}
                                    error={
                                        errors[
                                            TECHNOLOGY_STEP_FIELDS
                                                .OTHER_TECHNOLOGY_DESCRIPTION
                                        ]
                                    }
                                    as="textarea"
                                />
                            </SInputContainer>
                        )}
                    </FormSection>

                    <Divider />

                    <FormSection
                        title={formatMessage({
                            id: 'estimate-the-project.technology-step.third-party-integrations.title',
                        })}
                        helperText={formatMessage({
                            id: 'estimate-the-project.select-all-that-apply',
                        })}
                        error={
                            errors[
                                TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS
                            ]
                        }
                        isRequired
                    >
                        <OptionGrid
                            options={THIRD_PARTY_INTEGRATIONS_OPTIONS}
                            control={control}
                            fieldKey={
                                TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS
                            }
                            onChange={() => {
                                if (isSubmitted) {
                                    trigger(
                                        TECHNOLOGY_STEP_FIELDS.THIRD_PARTY_INTEGRATIONS,
                                    );
                                }
                            }}
                        />
                    </FormSection>
                </>
            )}
        </StepWrapper>
    );
};
