import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { ESTIMATE_PROJECT_STEP_COUNT } from 'views/estimate-project/constants';
import { Button } from 'components/button-new';
import { CONSTANTS } from 'constants/styles/constants';
import { func, number } from 'prop-types';

const SDiv = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--white-color);
    padding: 1.5rem 0;
    border-top: 1px solid var(--gray-color-70);

    ${CONSTANTS.MEDIA.max840`
        position: static;
        padding-right: 0
    `}
`;

const SNextButton = styled(Button)`
    margin-left: auto;
`;

export const Buttons = ({
    className,
    currentStep,
    completeStep,
    handleSubmit,
    handleChangeStep,
}) => {
    const { formatMessage } = useIntl();

    const resolveBackButton = () => {
        const properties = {
            variant: 'outline',
            onClick: () => handleChangeStep(currentStep - 1),
        };

        return currentStep === 1 ? null : (
            <Button {...properties}>
                <FormattedMessage id="estimate-the-project.back" />
            </Button>
        );
    };

    const resolveNextButton = () => {
        const isLastStep = currentStep === ESTIMATE_PROJECT_STEP_COUNT;
        const label = isLastStep
            ? 'general.estimateProject'
            : 'estimate-the-project.nextStep';

        const props = isLastStep
            ? {
                  component: 'submit',
                  onClick: handleSubmit,
                  submitValue: formatMessage({ id: label }),
              }
            : {
                  onClick: () => {
                      completeStep(currentStep);
                      handleChangeStep(currentStep + 1);
                  },
                  children: formatMessage({ id: label }),
              };

        return <SNextButton {...props} />;
    };

    return (
        <SDiv className={className}>
            {resolveBackButton()}
            {resolveNextButton()}
        </SDiv>
    );
};

Buttons.propTypes = {
    handleSubmit: func,
    handleNext: func,
    handlePrev: func,
    currentStep: number,
};
