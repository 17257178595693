import { useCallback, useRef } from 'react';

export const useFileUpload = ({
    value,
    onChange,
    allowedFileSize,
    allowedMaxFilesCount,
    allowedTotalFilesSize,
    onFileUploadError,
    onExceedingFilesCount,
    onExceedingTotalFilesSize,
    onExceedingFileSize,
}) => {
    const inputFileRef = useRef(null);

    const onAddFiles = useCallback((e) => {
        e.preventDefault();
        inputFileRef?.current?.click();
    }, []);

    const onFileRemove = useCallback(
        (name) => (e) => {
            e.preventDefault();
            onChange(value.filter((file) => file.name !== name));
        },
        [value, onChange],
    );

    const processFiles = useCallback(
        (files) => {
            const arrayOfFiles = Object.values(files);

            const uniqueFiles = [...value, ...arrayOfFiles].reduce(
                (acc, file) => {
                    if (!acc.some((f) => f.name === file.name)) {
                        acc.push(file);
                    }
                    return acc;
                },
                [],
            );
            if (
                allowedMaxFilesCount &&
                uniqueFiles.length > allowedMaxFilesCount
            ) {
                onExceedingFilesCount();
                return;
            }

            if (
                uniqueFiles.some(
                    (file) =>
                        file.size > (allowedFileSize || allowedTotalFilesSize),
                )
            ) {
                onExceedingFileSize();
                return;
            }

            const mergedFilesTotalSize = uniqueFiles.reduce(
                (acc, file) => acc + file.size,
                0,
            );
            if (mergedFilesTotalSize > allowedTotalFilesSize) {
                onExceedingTotalFilesSize();
                return;
            }

            onChange(uniqueFiles);
        },
        [
            onChange,
            allowedFileSize,
            allowedMaxFilesCount,
            allowedTotalFilesSize,
            value,
            onExceedingFilesCount,
            onExceedingTotalFilesSize,
            onExceedingFileSize,
        ],
    );

    // handleFileChange uses processFiles
    const handleFileChange = (e) => {
        if (!e.target.files) {
            onFileUploadError();
            return;
        }
        processFiles(e.target.files);
        e.target.value = null; // Clear input
    };

    // handleFileDrop uses processFiles
    const handleFileDrop = (e) => {
        e.preventDefault();
        if (!e.dataTransfer.files) {
            onFileUploadError();
            return;
        }
        processFiles(e.dataTransfer.files);
    };

    return {
        inputFileRef,
        onAddFiles,
        onFileRemove,
        handleFileChange,
        handleFileDrop,
    };
};
