import React from 'react';
import styled from 'styled-components';
import { func, string } from 'prop-types';
import DocumentIcon from 'svgs/icons/ic-document.svg';
import CrossIcon from 'svgs/icons/ic-red-cross.svg';

const SContainer = styled.div`
    display: flex;
    flex: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    border: 1px solid var(--gray-color-70);
    border-radius: 1rem;
    padding: 0.75rem 1rem 0.75rem 1.25rem;

    & > span {
        flex: 1;
        text-align: start;
    }
`;

const SLeftContainer = styled.div`
    display: flex;
    gap: 0.75rem;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;
    text-wrap: break-word;
    word-break: break-all;
`;

const SRemoveButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1rem;
    min-height: 1rem;
    border: none;
    cursor: pointer;

    & > svg {
        width: 1.125rem;
        height: 1.125rem;
    }
`;

const SDocumentIcon = styled(DocumentIcon)`
    min-width: 1.125rem;
    min-height: 1.125rem;
`;

export const FileItem = ({ name, onRemove }) => {
    return (
        <SContainer>
            <SLeftContainer>
                <SDocumentIcon />
                <span>{name}</span>
            </SLeftContainer>
            <SRemoveButton onClick={onRemove}>
                <CrossIcon />
            </SRemoveButton>
        </SContainer>
    );
};

FileItem.propTypes = {
    name: string.isRequired,
    onRemove: func.isRequired,
};
