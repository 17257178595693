import { ESTIMATE_PROJECT_FORM_FIELDS } from '../constants';

export const ESTIMATE_PROJECT_TYPES = {
    UPDATE_DATA: 'updateData',
    SET_STEP: 'setStep',
    RESET: 'reset',
};

export const estimateProjectInitialState = {
    formData: {
        [ESTIMATE_PROJECT_FORM_FIELDS.FULL_NAME]: '',
        [ESTIMATE_PROJECT_FORM_FIELDS.EMAIL]: '',
        [ESTIMATE_PROJECT_FORM_FIELDS.MESSAGE]: '',
        [ESTIMATE_PROJECT_FORM_FIELDS.SOURCE]: '',
        [ESTIMATE_PROJECT_FORM_FIELDS.PRIVACY_POLICY]: false,
        [ESTIMATE_PROJECT_FORM_FIELDS.NDA]: false,
        step2: {},
        step3: {},
        step4: '',
    },
    currentStep: 1,
};

export const estimateProjectFormReducer = (state, action) => {
    switch (action.type) {
        case ESTIMATE_PROJECT_TYPES.UPDATE_DATA:
            return {
                ...state,
                formData: {
                    ...state.formData,
                    ...action.payload.data,
                },
                currentStep: action.payload.newStep || state.currentStep,
            };

        case ESTIMATE_PROJECT_TYPES.SET_STEP:
            return {
                ...state,
                currentStep: action.payload,
            };

        case ESTIMATE_PROJECT_TYPES.RESET:
            return estimateProjectInitialState;

        default:
            throw new Error('Reducer error');
    }
};
