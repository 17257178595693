import React from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { node, string } from 'prop-types';

const SWrapper = styled.div`
    ${CONSTANTS.MEDIA.max840`
        flex: 1;
    `}
`;

const SHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 40rem;
`;

const SDescription = styled.p`
    color: var(--black-text-color);
    font-weight: 300;
`;

const STitle = styled.h1`
    font-weight: 700;
    font-size: clamp(1.75rem, 1.5739rem + 0.7512vw, 2.25rem);
`;

const SContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
`;

export const StepWrapper = ({ title, description, children }) => {
    return (
        <SWrapper>
            <SHeaderWrapper>
                <STitle>{title}</STitle>
                <SDescription>{description}</SDescription>
            </SHeaderWrapper>
            <SContentWrapper>{children}</SContentWrapper>
        </SWrapper>
    );
};

StepWrapper.propTypes = {
    title: string.isRequired,
    description: string.isRequired,
    children: node.isRequired,
};
