import { STEP, STEP_FIELDS_MAP } from '../constants';
import { ADDITIONAL_INFO_STEP_FIELDS } from '../steps/additional-info-step/_constants';
import { BUDGET_STEP_FIELDS } from '../steps/budget-step/_constants';
import { CONTACT_INFO_STEP_FIELDS } from '../steps/contact-info-step/_constants';
import { SERVICES_STEP_FIELDS } from '../steps/services-step/_constants';
import { TECHNOLOGY_STEP_FIELDS } from '../steps/technology-step/_constants';

export const getCompletedSteps = (fieldValues) => {
    return Object.entries({
        [STEP.CONTACT_INFO_STEP]:
            !!fieldValues[CONTACT_INFO_STEP_FIELDS.FULL_NAME] &&
            !!fieldValues[CONTACT_INFO_STEP_FIELDS.EMAIL],
        [STEP.SERVICES_STEP]:
            Object.values(fieldValues[SERVICES_STEP_FIELDS.SERVICES]).some(
                Boolean,
            ) &&
            Object.values(fieldValues[SERVICES_STEP_FIELDS.SCOPE]).some(
                Boolean,
            ),
        [STEP.TECHNOLOGY_STEP]:
            fieldValues[TECHNOLOGY_STEP_FIELDS.IS_OPEN_TO_SUGGESTIONS] !== null,
        [STEP.BUDGET_STEP]:
            !!fieldValues[BUDGET_STEP_FIELDS.BUDGET_RANGE] &&
            !!fieldValues[BUDGET_STEP_FIELDS.PROJECT_TIMELINE],
        [STEP.ADDITIONAL_INFO_STEP]:
            !!fieldValues[ADDITIONAL_INFO_STEP_FIELDS.PRIVACY_POLICY],
    })
        .filter(([, value]) => value)
        .map(([key]) => key);
};

export const getErrorSteps = (errors) => {
    const errorKeys = Object.keys(errors);

    return Object.entries(STEP_FIELDS_MAP)
        .map(([key, value]) => {
            if (
                errorKeys.some((errorKey) =>
                    Object.values(value).includes(errorKey),
                )
            ) {
                return key;
            }

            return null;
        })
        .filter(Boolean);
};

export const hasStepError = (step, errors) => {
    return Object.keys(errors).some((key) => {
        return Object.values(step).includes(key);
    });
};
