export const ADDITIONAL_INFO_STEP_FIELDS = {
    NDA: 'nda',
    PRIVACY_POLICY: 'privacyPolicy',
    ADDITIONAL_DETAILS: 'additionalDetails',
    ATTACHMENTS: 'attachments',
};

export const ADDITIONAL_INFO_STEP_DEFAULT_VALUES = {
    [ADDITIONAL_INFO_STEP_FIELDS.NDA]: false,
    [ADDITIONAL_INFO_STEP_FIELDS.PRIVACY_POLICY]: false,
    [ADDITIONAL_INFO_STEP_FIELDS.ADDITIONAL_DETAILS]: '',
    [ADDITIONAL_INFO_STEP_FIELDS.ATTACHMENTS]: [],
};
