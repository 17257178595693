import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { BUDGET_STEP_FIELDS } from 'views/estimate-project/steps/budget-step/_constants';
import { CONTACT_INFO_STEP_FIELDS } from 'views/estimate-project/steps/contact-info-step/_constants';
import { SERVICES_STEP_FIELDS } from 'views/estimate-project/steps/services-step/_constants';
import { TECHNOLOGY_STEP_FIELDS } from 'views/estimate-project/steps/technology-step/_constants';

const SWrapper = styled.div`
    display: none;
`;

export const HubSpotHiddenInputs = () => {
    const { register, watch } = useFormContext();

    const {
        name,
        email,
        phoneNumber,
        companyName,
        role,
        services,
        scope,
        otherServicesDescription,
        technologyStack,
        thirdPartyIntegrations,
        otherTechnologyDescription,
        budgetRange,
        projectTimeline,
    } = watch();

    const getCheckedValues = (obj) => {
        return Object.entries(obj).map(([key, val]) => {
            return val ? (
                <Fragment key={key}>
                    <label htmlFor={key}>{key}</label>
                    <input
                        {...register(key)}
                        type="checkbox"
                        name={key}
                        checked={val}
                        value={val}
                    />
                </Fragment>
            ) : null;
        });
    };

    return (
        <SWrapper>
            {/* CONTACT INFO STEP */}
            <input
                type="email"
                {...register(CONTACT_INFO_STEP_FIELDS.EMAIL)}
                value={email}
            />
            <input
                type="text"
                {...register(CONTACT_INFO_STEP_FIELDS.FULL_NAME)}
                value={name}
            />
            <input
                type="text"
                {...register(CONTACT_INFO_STEP_FIELDS.PHONE_NUMBER)}
                value={phoneNumber}
            />
            <input
                type="text"
                {...register(CONTACT_INFO_STEP_FIELDS.COMPANY_NAME)}
                value={companyName}
            />
            <input
                type="text"
                {...register(CONTACT_INFO_STEP_FIELDS.ROLE_POSITION)}
                value={role}
            />

            {/* SERVICES STEP */}
            {getCheckedValues(services)}
            {getCheckedValues(scope)}
            <input
                type="text"
                {...register(SERVICES_STEP_FIELDS.OTHER_SERVICES_DESCRIPTION)}
                value={otherServicesDescription}
            />

            {/* TECHNOLOGY STEP */}
            {getCheckedValues(technologyStack)}
            {getCheckedValues(thirdPartyIntegrations)}
            <input
                type="text"
                {...register(
                    TECHNOLOGY_STEP_FIELDS.OTHER_TECHNOLOGY_DESCRIPTION,
                )}
                value={otherTechnologyDescription}
            />

            {/* BUDGET STEP */}
            <label htmlFor={BUDGET_STEP_FIELDS.BUDGET_RANGE}>
                {budgetRange}
            </label>
            <input
                type="radio"
                {...register(BUDGET_STEP_FIELDS.BUDGET_RANGE)}
                value={budgetRange}
            />
            <label htmlFor={BUDGET_STEP_FIELDS.PROJECT_TIMELINE}>
                {projectTimeline}
            </label>
            <input
                type="radio"
                {...register(BUDGET_STEP_FIELDS.PROJECT_TIMELINE)}
                value={projectTimeline}
            />
        </SWrapper>
    );
};
